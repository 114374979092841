<!--
 * @Author       : JiangChao
 * @Date         : 2022-02-19 11:51:03
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-04-15 19:45:53
 * @Description  : 聯繫我們
-->
<template>
  <div>
    <div class="container-pa32">
      <br />
      <br />
      <br />
      <div class="logo">
        <img
          class="signal-img"
          :src="require('@/assets/imgs/svg/0527-k.svg')"
        />
      </div>
      <br />
      <br />
      <br />
      <div class="contact-words">
        <span class="text-875 text-b">感謝您使用旅電</span><br />
        <span class="text-875 text-b">
          若您對旅電有任何商業合作或使用上的問題，歡迎您來信/來電，我們將盡快與您聯絡
        </span>
        <br />
         <span class="text-875 text-b">
         感謝
        </span>
      </div>
      <br />
      <br />
      <br />
      <div class="margin-left-24">
        <div class="flex-container flex-center">
          <img
            class="front-img-w14"
            :src="require('@/assets/imgs/svg/0527-l.svg')"
          />
          <span class="text-875 text-b">10:00-18:00</span>
        </div>
        <div class="flex-container flex-center">
          <img
            class="front-img-w14"
            :src="require('@/assets/imgs/svg/0527-m.svg')"
          />
          <span class="text-875 text-b" @click="goLine">LINE客服</span>
        </div>
        <div class="flex-container flex-center">
          <img
            class="front-img-w14"
            :src="require('@/assets/imgs/svg/0527-n.svg')"
          />
          <span class="text-875 text-b">pluginn.info@gmail.com</span>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div class="bottom-name">
        <img :src="require('@/assets/imgs/svg/0527-c.svg')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
      goLine() {
            window.location.href = "https://page.line.me/100mvybr";
        },
  }
};
</script>
